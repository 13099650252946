.AboutUs-container{
    color: rgb(66,68,75);
}
.AboutUs-container .Test-card{
    width: 20rem;

    justify-content: space-between;
     box-shadow: var(--shadow);
     border-radius:15px;
     height: 18rem;
 }
 .team{
    flex-direction: row;
    align-items: baseline;
 }
 .AboutUs-container .card-body{
    justify-content: space-between;

 }
 .AboutUs-container .card-body .Aceo-id{
    margin-left: 10px;
    flex: 2;
 }
 .Bu{
    height: 4rem;
    width: 8rem;
 }
 .Bu .B{
    width: 100%;
    height: 100%;
    border-radius: 10px;
 }
.div{
    background-color: aliceblue;
}
.Feature-container{
    width: 100%;
    justify-content: space-between;
}
.Feature-card{
    width: 160px;
    height: 100px;
    box-shadow: var(--shadow);
    border-radius: 6px;
}
.Ex-wrapper{
    width: 100%;
     position: relative;
     padding-bottom: 2rem;
     z-index: 1;
    
}
.Exp-container{
    padding-left: 1.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items:column;
}
.Ex-footer{
    display: flex;
    justify-content:space-around;
    width: 100%;
}
.Ex-footer.icon-container{
    width: 100%;
}
.Ex-footer.button{
    flex:1;
   
}
.Ex-footer.span{
    flex: 1;
    margin-left:30px ;
}
/* right-side */
.Ex-right{
    flex: 1; /* Take half of the Ex-container */
  
}
.image-container{
    width :100%;
    height: 100%;
    overflow: hidden;
}
.Ex-right>img{
    width:100%;
    height: 100%;
}
.Ex-right .gray-background{
    height: 30rem;
    width: 30rem;
    background: rgb(219,218,218);
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    position: absolute;
    right: 0%;
    z-index: -1;
}
/* leftSide */
.Ex-left{
   flex :1; /* Take half of the Ex-container */
   
    gap: 3rem;
    border-top: 3rem;
    border-left: 5rem ;
    border-right: 5rem;
    justify-content: space-around;
}
.titles{
    position:relative;
    z-index: 1;
}
.titles>span{
    
    font-size: 4rem;
  
    color: rgb(66,68,75);
}
.titles>p{
    font-size:2rem;
    color: rgb(66,68,75);
    width: 100%;
}
.Ex-left .line-container{
    border-top: 3px solid black; /* You can adjust the width and color as needed */
    width: 100%;
    height: 100px;
    color: rgba(66,68,75);
}
.button1{
    cursor: pointer;
    width: 100%;
    height: 100%;
    align-items: center;
}
.button1 .f-icon{
font-size: 30px;
}
.icon-container{
    width: 15%;
}
.V-icon{
    font-size: 40px;
    color: rgb(66,68,75);
    cursor: pointer;
}
@media (max-width :640px){
    .Exp-container{
        flex-direction: column;
        margin-top: 2rem;
    }
    .Ex-left{
        width: 100%;
    }
    .titles{
        width: 95%;
    }
    .image-container{
        width: 100%;
        height: 25rem;
    }
    .image-container>img{
        width: 100%;
    }
    .Ex-right .gray-background{
        height: 13rem;
        width: 13rem;
        top: 67%;
    }
}

